.ui-active-filters {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;

  &__filter {
    margin-right: 10px;
  }

  &__filter-label {
    margin-top: 2px;
    color: $color-emperor;
    font-size: 9px;
    text-transform: uppercase;
    transform: translateY(-1px);

    &--hidden {
      visibility: hidden;
    }
  }

  &__filter-values {
    display: flex;
    flex-wrap: wrap;
  }

  &__value {
    display: flex;
    justify-content: space-between;
    padding: 4px 8px;
    border: 1px solid $color-light-silver;
    margin-right: 3px;
    margin-bottom: 4px;
    background-color: $color-light-alabaster;
    cursor: pointer;
    font-size: 13px;

    &--mandatory {
      border-color: $color-silver-chalice;
      background-color: $color-black-white;
    }
  }

  &__value-text {
    color: $color-gray;

    &-flag:before {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin: 3px 5px 3px 0;
      background-color: $ui-multi-switch-slider-color-active;
      content: '';
      vertical-align: bottom;
    }

    &-flag--off {
      text-decoration: line-through;

      &:before {
        background-color: $ui-multi-switch-slider-color-inactive;
      }
    }
  }

  &__value-remove {
    margin-left: 6px;
    color: $ui-multi-switch-slider-color-active;
    cursor: pointer;
    font-weight: bold;
  }
}
