.ui-available-filters {
  display: flex;
  width: 206px;
  align-items: center;
  align-self: baseline;
  margin-right: -8px;
  margin-left: 14px;

  &__select {
    @include truncate;
  }

  &__discard-wrapper {
    width: 26px;
    margin-top: -2px;
    margin-left: 4px;
  }

  &__discard {
    cursor: pointer;

    &:before {
      content: $icon-delete;
      font-family: $icomoon-font-family;
    }
  }
}
