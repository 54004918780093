.ui-modal-activation-errors {
  .ui-modal__header-wrapper {
    border: 1px solid $ui-modal-popup-error-color;
    background-color: $ui-modal-popup-error-color;
  }

  &__title {
    font-weight: bold;
  }

  &__list {
    margin-left: 14px;
    list-style-type: unset;
  }
}
