.ui-filter-item {
  display: flex;
  flex-direction: column;
  padding: 6px;
  border: 1px solid transparent;

  &--modified {
    .ui-input {
      background-color: $color-modified;
    }
  }

  &--switch {
    flex-direction: row;
    justify-content: space-between;
  }

  &__selected-values {
    width: 100%;
  }

  &:hover {
    border: 1px solid $color-gallery;
    background: $color-bg-primary;
  }

  label {
    display: inline-block;
    width: min-content;
    margin-top: 6px;
    margin-bottom: 3px;
    color: $color-dove-gray;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__product-type {
    margin-bottom: 4px;

    .multiselect__content-wrapper {
      max-height: unset !important;
    }
  }

  .ui-input {
    border: 1px solid $color-light-silver;
    font-size: 15px;
  }

  .ui-filter-item-selected-value {
    margin-top: 5px;
  }
}
