.ui-message {
  margin-bottom: 6px;
  box-shadow: 0 0 5px rgba($color-light-silver, 0.2);

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 6px 10px 8px;
    border: 1px solid $color-lines-primary;
    background-color: $color-wild-sand;
    color: $color-mine-shaft;
    cursor: pointer;
    font-size: 14px;
  }

  &__header-email {
    margin-right: 10px;
    font-size: 15px;
    font-weight: bold;
  }

  &__header-date-text {
    margin-right: 5px;
    font-weight: bold;
  }

  &__content {
    padding: 10px;
    border: 1px solid $color-alto;
    border-top: none;
    margin: 0;
    background-color: rgba($color-white, 0.975);
  }

  &__attachments-text {
    margin-top: 6px;
    color: $color-boulder;
    font-size: 16px;
    font-weight: bold;
  }

  &__attachments-list {
    list-style-type: decimal;
  }

  &__attachments-list-item {
    margin-left: 24px;
    font-size: 13px;
  }
}
