.ui-filter-item-selected-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $color-brand-primary;

  &__remove {
    cursor: pointer;
    opacity: 0.7;
  }
}
