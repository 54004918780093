.ui-table-actions {
  display: flex;
  align-items: center;
  justify-content: center;

  &__icon-wrapper:not(:last-child) {
    margin-right: 5px;
  }

  &__icon {
    cursor: pointer;
    font-size: 14px;
  }
}

.ui-table-poster {
  display: flex;
  height: 64px;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid $color-silver-chalice;
  background-color: $color-bg-primary;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  &--contain {
    background-size: contain;
  }

  &:hover {
    .ui-table-poster__icon {
      cursor: pointer;

      &:before {
        display: block;
        height: 25px;
        background-color: $color-black;
        border-radius: 50%;
        color: $color-silver;
        font-family: $icomoon-font-family;
        font-size: 28px;
        font-weight: 600;
        text-indent: -1px;
      }

      &:hover:before {
        color: $color-brand-primary;
      }

      &--video:before {
        content: $icon-play;
      }

      &--audio:before {
        content: $icon-audio;
      }
    }
  }
}

.ui-table-item-details {
  &__top {
    display: flex;
    align-items: flex-start;
  }

  &__header {
    padding: 0 !important;
    border: none !important;
    margin-right: 10px;
    margin-bottom: 0 !important;
    font-size: 16px !important;

    @include truncate;
  }

  &__link {
    padding-right: 5px;
    color: $color-oxford-blue;
  }

  &__description {
    color: $color-silver;
    line-height: 18px;
  }

  &__pills {
    display: flex;
    flex-wrap: wrap;
  }

  &__pill {
    padding: 2px 5px;
    margin: 3px 3px 0 0;
    background-color: $color-silver-chalice;
    color: $color-white;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;

    &--main {
      background-color: $main-category-pill-color;
    }

    &--category {
      background-color: $category-pill-color;
    }

    &--display-schedule-type {
      background-color: $color-blue-violet;
    }
  }
}

.ui-table-detail-link {
  @include truncate;

  &__link {
    color: $color-oxford-blue;
  }
}

.ui-table-media-format {
  display: flex;
  align-items: center;
}

.ui-table-availability {
  display: flex;
  justify-content: space-between;
}

.ui-table-device-info {
  font-size: 14px;

  &__browser-icon {
    width: 14px;
    height: 14px;
  }
}

.ui-table-promotion-product,
.ui-table-promotion-criteria {
  display: flex;
  align-items: center;

  &__icon {
    padding-right: 0.3rem;
    color: $color-light-red;
    font-size: 130%;
  }
}

.ui-table-data {
  @include truncate;
}
