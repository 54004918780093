.ui-section-header {
  display: flex;

  &--inactive {
    opacity: 0.4;
  }

  &__move {
    margin-right: 10px;
    cursor: move;
    font-size: 22px;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: -3px;
    margin-right: auto;
  }

  &__content-title {
    margin-right: 10px;
    color: $color-brand-primary;
    font-size: 20px;
  }

  &__content-text {
    padding-right: 5px;
    border-right: 1px solid currentcolor;
    margin-right: 4px;
    font-size: 14px;
    font-weight: 300;

    &:first-of-type {
      padding-left: 5px;
      border-left: 1px solid currentcolor;
    }
  }

  &__actions {
    display: flex;
    height: max-content;
    align-items: center;
    margin-left: 14px;
    color: $color-font-primary;
  }

  &__actions-element {
    margin-right: 4px;
    cursor: pointer;
    font-size: 15px;

    &:last-of-type {
      padding-right: 7px;
      border-right: 1px solid currentcolor;
    }
  }

  &__actions-toggle {
    margin-left: 4px;
  }

  &__actions-toggle-icon {
    width: 31px;
    height: 22px;
    padding: 2px 10px;
    background-color: $color-silver-chalice;
    color: $color-white;
    cursor: pointer;
    font-size: 12px;
    line-height: 18px;

    &.icon-up {
      background-color: $color-brand-primary;
    }
  }
}
