@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?v1fyx0') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?v1fyx0') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?v1fyx0##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-panasonic_tv {
  &:before {
    content: $icon-panasonic_tv; 
  }
}
.icon-tenants {
  &:before {
    content: $icon-tenants; 
  }
}
.icon-season {
  &:before {
    content: $icon-season; 
  }
}
.icon-serial-2 {
  &:before {
    content: $icon-serial-2; 
  }
}
.icon-advert {
  &:before {
    content: $icon-advert; 
  }
}
.icon-dictionaries {
  &:before {
    content: $icon-dictionaries; 
  }
}
.icon-episodes {
  &:before {
    content: $icon-episodes; 
  }
}
.icon-logo {
  &:before {
    content: $icon-logo; 
  }
}
.icon-apple_tv {
  &:before {
    content: $icon-apple_tv; 
  }
}
.icon-user-group {
  &:before {
    content: $icon-user-group; 
  }
}
.icon-clients {
  &:before {
    content: $icon-clients; 
  }
}
.icon-logout {
  &:before {
    content: $icon-logout; 
  }
}
.icon-ppv {
  &:before {
    content: $icon-ppv; 
  }
}
.icon-add {
  &:before {
    content: $icon-add; 
  }
}
.icon-android {
  &:before {
    content: $icon-android; 
  }
}
.icon-android_tv {
  &:before {
    content: $icon-android_tv; 
  }
}
.icon-audio {
  &:before {
    content: $icon-audio; 
  }
}
.icon-avatars {
  &:before {
    content: $icon-avatars; 
  }
}
.icon-banners {
  &:before {
    content: $icon-banners; 
  }
}
.icon-browser {
  &:before {
    content: $icon-browser; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-calendar-detailed {
  &:before {
    content: $icon-calendar-detailed; 
  }
}
.icon-categories {
  &:before {
    content: $icon-categories; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-cut {
  &:before {
    content: $icon-cut; 
  }
}
.icon-delete {
  &:before {
    content: $icon-delete; 
  }
}
.icon-down {
  &:before {
    content: $icon-down; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-efficiency {
  &:before {
    content: $icon-efficiency; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-exp-menu {
  &:before {
    content: $icon-exp-menu; 
  }
}
.icon-fullscreen {
  &:before {
    content: $icon-fullscreen; 
  }
}
.icon-help {
  &:before {
    content: $icon-help; 
  }
}
.icon-hide-menu {
  &:before {
    content: $icon-hide-menu; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-ios {
  &:before {
    content: $icon-ios; 
  }
}
.icon-left {
  &:before {
    content: $icon-left; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-locked {
  &:before {
    content: $icon-locked; 
  }
}
.icon-login {
  &:before {
    content: $icon-login; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-movie {
  &:before {
    content: $icon-movie; 
  }
}
.icon-new {
  &:before {
    content: $icon-new; 
  }
}
.icon-eye-crossed {
  &:before {
    content: $icon-eye-crossed; 
  }
}
.icon-block {
  &:before {
    content: $icon-block; 
  }
}
.icon-operator {
  &:before {
    content: $icon-operator; 
  }
}
.icon-packages {
  &:before {
    content: $icon-packages; 
  }
}
.icon-pay {
  &:before {
    content: $icon-pay; 
  }
}
.icon-payments {
  &:before {
    content: $icon-payments; 
  }
}
.icon-pc {
  &:before {
    content: $icon-pc; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-playstation {
  &:before {
    content: $icon-playstation; 
  }
}
.icon-premiere {
  &:before {
    content: $icon-premiere; 
  }
}
.icon-product-catchup {
  &:before {
    content: $icon-product-catchup; 
  }
}
.icon-product-recorder {
  &:before {
    content: $icon-product-recorder; 
  }
}
.icon-product-timeshift {
  &:before {
    content: $icon-product-timeshift; 
  }
}
.icon-program {
  &:before {
    content: $icon-program; 
  }
}
.icon-question {
  &:before {
    content: $icon-question; 
  }
}
.icon-record {
  &:before {
    content: $icon-record; 
  }
}
.icon-records {
  &:before {
    content: $icon-records; 
  }
}
.icon-register {
  &:before {
    content: $icon-register; 
  }
}
.icon-right {
  &:before {
    content: $icon-right; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-sections {
  &:before {
    content: $icon-sections; 
  }
}
.icon-send {
  &:before {
    content: $icon-send; 
  }
}
.icon-serial {
  &:before {
    content: $icon-serial; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-sidebar-close {
  &:before {
    content: $icon-sidebar-close; 
  }
}
.icon-sidebar-medium {
  &:before {
    content: $icon-sidebar-medium; 
  }
}
.icon-sidebar-open {
  &:before {
    content: $icon-sidebar-open; 
  }
}
.icon-smallscreen {
  &:before {
    content: $icon-smallscreen; 
  }
}
.icon-smart_tv {
  &:before {
    content: $icon-smart_tv; 
  }
}
.icon-sort {
  &:before {
    content: $icon-sort; 
  }
}
.icon-sort-asc {
  &:before {
    content: $icon-sort-asc; 
  }
}
.icon-sort-desc {
  &:before {
    content: $icon-sort-desc; 
  }
}
.icon-statistics {
  &:before {
    content: $icon-statistics; 
  }
}
.icon-substract {
  &:before {
    content: $icon-substract; 
  }
}
.icon-success {
  &:before {
    content: $icon-success; 
  }
}
.icon-timeshift {
  &:before {
    content: $icon-timeshift; 
  }
}
.icon-tracking {
  &:before {
    content: $icon-tracking; 
  }
}
.icon-translations {
  &:before {
    content: $icon-translations; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-unlocked {
  &:before {
    content: $icon-unlocked; 
  }
}
.icon-up {
  &:before {
    content: $icon-up; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-wallet {
  &:before {
    content: $icon-wallet; 
  }
}
.icon-warning {
  &:before {
    content: $icon-warning; 
  }
}
.icon-watch {
  &:before {
    content: $icon-watch; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-zoom-in {
  &:before {
    content: $icon-zoom-in; 
  }
}
.icon-zoom-out {
  &:before {
    content: $icon-zoom-out; 
  }
}
.icon-content-lang {
  &:before {
    content: $icon-content-lang; 
  }
}
.icon-apps {
  &:before {
    content: $icon-apps; 
  }
}
.icon-preview {
  &:before {
    content: $icon-preview; 
  }
}
.icon-user-2 {
  &:before {
    content: $icon-user-2; 
  }
}
.icon-user-3 {
  &:before {
    content: $icon-user-3; 
  }
}

