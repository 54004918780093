.ui-available-filters-settings {
  padding: 10px 6px 5px;
  border: 1px solid $color-lines-primary;
  margin-bottom: 4px;
  background-color: $color-wild-sand;

  .ui-available-filters {
    width: auto;
    flex: 1;
    margin: 0;
  }

  &__save {
    margin-left: auto;
    white-space: nowrap;

    &--inactive {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &__toggle {
    position: relative;
    width: 16px;
    height: 16px;
    margin: 7px 6px 0 10px;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s;
    user-select: none;

    &--open {
      transform: rotate(180deg);
      transform-origin: center;
    }
  }

  &__list {
    padding: 6px 2px;
    margin-top: 11px;
  }

  &__default {
    font-weight: bold;
  }
}

.ui-available-filters-settings__list-item {
  display: flex;
  align-items: center;
  padding: 4px;
  border: 1px solid $color-alto;
  margin-bottom: 4px;
  background-color: $color-white;
  color: $color-dove-gray;
  cursor: default;

  p {
    @include truncate;
  }

  i {
    margin-top: 2px;
    color: $color-gray;
    cursor: pointer;
  }

  .ui-available-filters-settings__edit {
    margin-left: auto;
  }

  .ui-available-filters-settings__delete {
    margin-left: 5px;
  }

  &:hover {
    background-color: $color-white;
  }

  &.ui-sortable__ghost-item {
    opacity: 0.5;
  }

  &.ui-sortable__clone-item {
    border: 1px solid $color-silver-chalice;
    background-color: $color-white;
    color: $color-emperor;
  }

  .ui-sortable__handle-item {
    margin-right: 4px;
    color: $color-silver-chalice;
    cursor: move;
    font-size: 20px;
  }
}
