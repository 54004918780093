.break-all {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.d-block {
  display: block;
}

.flex-wrap {
  flex-wrap: wrap;
}

.font-style-italic {
  font-style: italic;
}

.h-100 {
  height: 100% !important;
}

.line-through {
  text-decoration: line-through;
}

.m-0 {
  margin: 0 !important;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.pointer-events-none {
  pointer-events: none;
}

.text-right {
  text-align: right;
}

.text-truncated {
  @include truncate;
}
