$icomoon-font-family: "redge-media-portal-bo" !default;
$icomoon-font-path: "fonts" !default;

$icon-panasonic_tv: "\e91b";
$icon-tenants: "\e91d";
$icon-season: "\e90c";
$icon-serial-2: "\e90d";
$icon-advert: "\e90e";
$icon-dictionaries: "\e910";
$icon-episodes: "\e911";
$icon-logo: "\e915";
$icon-apple_tv: "\e999";
$icon-user-group: "\e90b";
$icon-clients: "\e907";
$icon-logout: "\e944";
$icon-ppv: "\e900";
$icon-add: "\e901";
$icon-android: "\e902";
$icon-android_tv: "\e903";
$icon-audio: "\e904";
$icon-avatars: "\e905";
$icon-banners: "\e906";
$icon-browser: "\e908";
$icon-calendar: "\e909";
$icon-calendar-detailed: "\e90a";
$icon-categories: "\e90f";
$icon-close: "\e912";
$icon-cut: "\e913";
$icon-delete: "\e914";
$icon-down: "\e916";
$icon-download: "\e917";
$icon-edit: "\e918";
$icon-efficiency: "\e919";
$icon-email: "\e91a";
$icon-exp-menu: "\e91c";
$icon-fullscreen: "\e920";
$icon-help: "\e926";
$icon-hide-menu: "\e927";
$icon-image: "\e928";
$icon-ios: "\e929";
$icon-left: "\e92a";
$icon-link: "\e92b";
$icon-list: "\e92c";
$icon-locked: "\e92f";
$icon-login: "\e930";
$icon-mail: "\e933";
$icon-movie: "\e934";
$icon-new: "\e937";
$icon-eye-crossed: "\e938";
$icon-block: "\e939";
$icon-operator: "\e93a";
$icon-packages: "\e93b";
$icon-pay: "\e93d";
$icon-payments: "\e93e";
$icon-pc: "\e93f";
$icon-play: "\e940";
$icon-playstation: "\e941";
$icon-premiere: "\e942";
$icon-product-catchup: "\e945";
$icon-product-recorder: "\e946";
$icon-product-timeshift: "\e947";
$icon-program: "\e948";
$icon-question: "\e949";
$icon-record: "\e957";
$icon-records: "\e958";
$icon-register: "\e959";
$icon-right: "\e95c";
$icon-search: "\e95d";
$icon-sections: "\e95e";
$icon-send: "\e95f";
$icon-serial: "\e960";
$icon-settings: "\e963";
$icon-sidebar-close: "\e967";
$icon-sidebar-medium: "\e968";
$icon-sidebar-open: "\e969";
$icon-smallscreen: "\e96a";
$icon-smart_tv: "\e96b";
$icon-sort: "\e96c";
$icon-sort-asc: "\e96d";
$icon-sort-desc: "\e96e";
$icon-statistics: "\e96f";
$icon-substract: "\e972";
$icon-success: "\e973";
$icon-timeshift: "\e974";
$icon-tracking: "\e975";
$icon-translations: "\e976";
$icon-trash: "\e977";
$icon-unlocked: "\e979";
$icon-up: "\e97a";
$icon-upload: "\e97b";
$icon-user: "\e97d";
$icon-wallet: "\e983";
$icon-warning: "\e984";
$icon-watch: "\e986";
$icon-eye: "\e988";
$icon-zoom-in: "\e989";
$icon-zoom-out: "\e98a";
$icon-content-lang: "\e98b";
$icon-apps: "\e98e";
$icon-preview: "\e990";
$icon-user-2: "\e985";
$icon-user-3: "\e987";

