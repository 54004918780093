.settings-list {
  display: flex;
  flex-direction: column;
  margin: 0 20px 20px;

  &__key {
    position: relative;

    .ui-tooltip__question {
      position: absolute;
      top: 1px;
    }
  }

  &__button-wrapper {
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    margin-top: 30px;
  }
}
