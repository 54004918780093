.app-header {
  position: fixed;
  z-index: 10;
  top: 0;
  display: flex;
  width: 100%;
  height: $header-height;
  align-items: center;
  justify-content: space-between;
  padding: 15px 25px;
  background-color: $color-brand-primary;

  &__logo {
    height: 24px;
    margin-right: auto;
  }

  &__logo-badge:after {
    position: relative;
    top: -22px;
    left: 8px;
    padding: 3px 6px;
    background-color: rgba($color-white, 0.8);
    border-radius: 12px;
    color: $color-brand-primary;
    content: attr(data-env);
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__logo-link,
  &__logo-image {
    display: inline-block;
    height: 100%;
  }

  &__tenants {
    width: max-content;
    margin-right: 1rem;
  }

  &__applications {
    position: relative;
    margin: 0 14px 0 6px;
  }

  &__applications-button {
    display: flex;
    padding: 3px;
    background-color: transparent;
    color: $color-white;
    cursor: pointer;
    font-size: 20px;
  }

  &__applications-icon {
    font-size: 20px;
  }

  &__modal {
    position: absolute;
    z-index: 100;
    top: 44px;
    right: -8px;
    display: flex;
    width: auto;
    max-width: 352px;
    flex-direction: column;
    border: 1px solid $color-light-silver;
    background-color: $color-white;
    box-shadow: 1px 1px 8px rgba($color-dove-gray, 0.4);

    &:before {
      position: absolute;
      z-index: 100;
      top: -9px;
      right: 10px;
      border-right: 8px solid transparent;
      border-bottom: 9px solid $color-white;
      border-left: 8px solid transparent;
      content: '';
    }

    &:after {
      position: absolute;
      z-index: 99;
      top: -10px;
      right: 9px;
      border-right: 9px solid transparent;
      border-bottom: 10px solid $color-light-silver;
      border-left: 9px solid transparent;
      content: '';
    }
  }

  &__modal-content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 0 13px 10px;
    margin: 10px;
    color: $color-emperor;

    &--applications {
      min-width: 252px;
      justify-content: space-around;
    }

    &--operator {
      min-width: 340px;
      flex-direction: column;
      padding-top: 4px;
      padding-bottom: 0;
    }
  }

  &__application {
    display: flex;
    width: 96px;
    flex-direction: column;
    align-items: center;
    padding: 8px 2px;
    border: 1px solid transparent;
    margin-top: 10px;
    color: $color-brand-secondary;
    font-size: 12px;
    text-align: center;

    &:hover {
      border: 1px solid $color-light-silver;
      background-color: $color-bg-primary;
    }
  }

  &__application-logo {
    display: block;
    width: 60px;
    margin-bottom: 10px;
  }

  &__operator {
    position: relative;
    color: $color-white;

    .app-header__language {
      color: $color-font-primary;

      &--active {
        background-color: $color-font-primary;
        border-radius: 6px;
        color: $color-white;
      }
    }
  }

  &__operator-button {
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    color: $color-white;
    cursor: pointer;
  }

  &__operator-info {
    margin-right: 8px;
    font-size: 14px;
  }

  &__operator-icon {
    position: relative;
    width: 29px;
    height: 26px;
    font-size: 20px;

    &:before {
      position: absolute;
      top: 3px;
      left: 4px;
    }
  }

  &__modal-input {
    line-height: 15px;
  }

  &__modal-row {
    display: flex;
    align-items: center;
    margin: 5px 0;

    .button {
      margin-right: 8px;
    }
  }

  &__modal-icon {
    margin-right: 8px;
    font-size: 16px;
  }

  &__modal-actions {
    display: flex;
    justify-content: flex-end;
    padding: 6px;
    border-top: 1px solid $color-gallery;
    background-color: $color-wild-sand;
  }

  &__language {
    padding: 5px;
    border: none;
    margin-right: 6px;
    background-color: transparent;
    color: $color-white;
    cursor: pointer;

    &--active {
      background-color: rgb(255 255 255 / 80%);
      border-radius: 6px;
      color: $color-brand-primary;
    }
  }

  &__element {
    position: relative;
  }

  &__element-label {
    position: absolute;
    top: -15px;
    overflow: hidden;
    color: $color-white;
    font-size: 9px;
    font-weight: 600;
    white-space: nowrap;
  }
}
