.ui-sortable {
  &__clone-item {
    position: absolute;
    z-index: 100;
    pointer-events: none;
  }

  &__handle-item {
    cursor: move;
  }
}
