.translations-list {
  display: flex;
  flex-direction: column;

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 0 20px 20px;
  }
}
