.ad-break-list {
  &__vmap {
    display: flex;
    width: 100%;
    margin-left: 14px;

    &-label {
      margin-top: 4px;
      margin-right: 15px;
      color: $color-silver-chalice;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}
