.ui-search {
  position: relative;
  width: 220px;
  align-self: baseline;

  &__input {
    width: 100%;
    height: auto;
    padding-inline-end: 24px;
  }

  &__icon {
    position: absolute;
    top: 50%;
    margin-top: -7px;
    font-weight: 600;
    inset-inline-end: 6px;
    pointer-events: none;
  }
}

[dir='rtl'] .ui-search {
  direction: rtl;
}
