.list-wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow-y: hidden;
}

.loading .ui-table__loader {
  display: block;
}
