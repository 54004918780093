$color-goldenrod: #fbc96c;
$color-orange: orange;
$color-coral-red: #ff3a48;
$color-tamarillo: #801011;
$color-old-brick: #911c1d;
$color-venetian-red: #b90000;
$color-well-read: #b33232;
$color-light-red: #f00000;
$color-cosmos: #ffd6d6;
$color-black-white: #fffef5;
$color-alto: #ddd;
$color-light-alabaster: #fcfcfc;
$color-alabaster: #fafafa;
$color-darker-alabaster: #f7f7f7;
$color-athens-gray: #dfe2e6;
$color-gallery: #eee;
$color-concrete: #f2f2f2;
$color-silver: #bbb;
$color-light-silver: #ccc;
$color-white: #fff;
$color-wild-sand: #f6f6f6;
$color-silver-chalice: #aaa;
$color-light-silver-chalice: #a0a0a0;
$color-gray: #888;
$color-boulder: #777;
$color-dove-gray: #666;
$color-light-dove-gray: #737373;
$color-emperor: #555;
$color-tundora: #444;
$color-cod-gray: #1e1e1e;
$color-black: #000;
$color-mine-shaft: #333;
$color-oxford-blue: #3e4b5c;
$color-piction-blue: #4e99e8;
$color-curious-blue: #3588e0;
$color-dodger-blue: #1873ff;
$color-blue-violet: #4b4bba;
$color-shamrock: #2ecc71;
