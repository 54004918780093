.ui-panel {
  display: flex;
  flex-direction: column;
  padding: 16px 26px;
  border: 1px solid $color-alto;
  margin-bottom: 16px;
  background-color: $color-white;
  box-shadow: 0 0 20px rgba($color-light-silver-chalice, 0.1);

  &__header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    border-bottom: 1px solid $color-gallery;
    margin-bottom: 16px;
  }

  &__header-text {
    color: $color-light-dove-gray;
    font-size: 21px;
  }

  &__header-button {
    margin-left: 10px;
  }

  &__sub-header {
    padding-bottom: 5px;
    border-bottom: 1px solid $color-lines-primary;
    margin: 20px 0 12px;
    color: $color-gray;
    font-size: 15px;
  }
}
