$stat-counter-size: 26px;

.stats {
  display: flex;
  overflow: auto;
  padding: 0 20px;

  &__wrapper {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    margin-left: -20px;
  }
}

.stat-item {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
  padding: 20px;

  @media only screen and (max-width: 1400px) {
    max-width: 50%;
    flex: 0 0 50%;
  }

  @media only screen and (max-width: 1000px) {
    max-width: 100%;
    flex: 0 0 100%;
  }

  &__list {
    counter-reset: li;
  }

  &__header {
    padding-bottom: 4px;
    border-bottom: 1px solid $color-athens-gray;
    margin-bottom: 7px;
    font-size: 16px;
  }

  &__list-item {
    display: flex;
    align-items: center;
    padding: 4px 0;
    border-bottom: 1px solid $color-gallery;
    margin-left: 0;

    &:hover {
      background-color: $color-concrete;
    }

    &:last-child {
      border: none;
    }
  }

  &__label {
    display: flex;
    min-width: 0;
    flex: 1;
    align-items: center;
    color: $color-dove-gray;

    &:before {
      display: flex;
      width: $stat-counter-size;
      height: $stat-counter-size;
      flex: 0 0 $stat-counter-size;
      align-items: center;
      justify-content: center;
      margin-right: 7px;
      background-color: $color-brand-primary;
      color: $color-white;
      content: counter(li);
      counter-increment: li;
      font-size: 13px;
      text-align: center;
    }

    &--disabled {
      cursor: default;
      pointer-events: none;
    }

    a {
      color: $color-oxford-blue;

      @include truncate;
    }
  }

  &__list-item:nth-child(n + 4) {
    label:before {
      background-color: $color-silver;
    }
  }

  &__value {
    padding: 3px 10px;
    margin-left: auto;
    font-weight: bold;
    text-align: right;
  }
}
