.ui-tabs {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;

  &__item {
    border: 1px solid $color-silver;
    margin: 1px 3px 1px 1px;
    background-color: $color-silver;
    box-shadow: 0 0 10px rgba($color-dove-gray, 0);

    &--active {
      border: 1px solid $color-brand-primary;
      background-color: $color-brand-primary;
    }
  }

  &__item-link {
    display: inline-block;
    padding: 5px 12px;
    color: $color-white;
    font-weight: 300;
  }
}
