.button {
  height: 21px;
  box-sizing: content-box;
  padding: 4px 11px;
  border: 1px solid $color-alto;
  background-color: $color-white;
  box-shadow: 1px 1px 8px rgba($color-light-silver, 0.2), inset 1px 1px 2px rgba($color-white, 0.1);
  color: $color-dove-gray;
  cursor: pointer;
  font-size: 12px;
  line-height: 21px;
  vertical-align: middle;

  &:disabled {
    cursor: default;
    opacity: 0.6;
  }
}

.button-info {
  padding-right: 22px;
  padding-left: 22px;
  border-color: $color-piction-blue;
  background-color: $color-piction-blue;
  box-shadow: none;
  color: $color-white;
  font-size: 13px;

  &:not([disabled]) {
    &:hover {
      border-color: $color-curious-blue;
      background-color: $color-curious-blue;
    }

    &:active,
    &:focus {
      border-color: $color-curious-blue;
    }
  }
}
