.login {
  display: flex;
  overflow: auto;
  flex-flow: column;
  background-image: url('/static/img/login-background.jpg');
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: cover;

  &__header {
    display: flex;
    justify-content: flex-end;
    padding: 20px 30px;
  }

  &__header-language {
    padding: 0 8px;
    border: none;
    background-color: transparent;
    color: $color-emperor;
    cursor: pointer;
    font-size: 18px;

    &:not(:last-of-type) {
      border-right: 1px solid $color-emperor;
    }

    &--active {
      color: $color-white;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  &__container {
    display: flex;
    width: 100%;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
  }

  &__description {
    display: none;
    width: 60%;
    color: $color-white;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.75;
    text-align: left;
  }

  &__logo-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    height: 36px;
  }

  &__form {
    display: flex;
    width: 340px;
    flex-flow: column;
    align-items: flex-start;
    color: $color-white;

    &--with-sso {
      min-height: 340px;
      padding: 30px 20px;
    }

    &--with-email-and-password {
      justify-content: space-between;
    }
  }

  &__form-header {
    margin-bottom: 8px;
    font-size: 32px;
    font-weight: 600;
  }

  &__form-inputs {
    width: 100%;
  }

  &__form-sso {
    text-align: center;
  }

  &__form-input-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 16px;

    &:first-child {
      margin-top: 20px;
    }

    &:last-child {
      margin-bottom: 30px;
    }
  }

  &__form-label {
    margin: 10px 0;
    font-weight: 600;
  }

  &__form-input {
    height: auto;
    padding: 10px;
    border: 1px solid $color-cod-gray;
    background-color: $color-cod-gray;
    border-radius: 5px;

    &--invalid {
      border: 1px solid $color-error;
    }
  }

  &__form-submit {
    padding: 10px 20px;
    background-color: $color-brand-primary;
    border-radius: 8px;
    color: $color-white;
    cursor: pointer;
    font-weight: 600;

    &:hover {
      background-color: $color-old-brick;
    }

    &--sso {
      padding: 10px 16px;
      margin-top: 20px;
      background-color: $color-white;
      color: $color-black;
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      text-transform: none;

      &:hover {
        background-color: $color-wild-sand;
      }
    }
  }

  &__form-errors {
    margin-bottom: 30px;
    color: $color-white;
    font-size: 13px;
  }

  &__form-error {
    display: flex;
    align-items: center;
    margin: 6px 0;
    color: $color-error;
    font-size: 14px;

    &:before {
      margin-right: 10px;
      content: $icon-warning;
      font-family: $icomoon-font-family;
      font-size: 18px;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    padding: 35px 0;
    font-weight: 600;
  }

  &__footer-text {
    margin-right: 5px;
    color: $color-dove-gray;

    em {
      color: $color-gray;
    }
  }

  &__footer-link {
    color: $color-dove-gray;

    &:hover {
      color: $color-white;
    }
  }

  @media only screen and (min-width: 1000px) {
    background-position: center center;

    &__content {
      flex-direction: row;
    }

    &__logo {
      height: 45px;
      margin-bottom: 20px;
    }

    &__container {
      width: 460px;
      height: 100%;
      align-items: flex-start;
      padding-right: 90px;
      border-right: 1px solid rgb($color-tundora, 0.5);

      &--without-email-and-password {
        justify-content: flex-start;
        padding-top: 41px;
      }
    }

    &__description {
      display: block;
      width: 90%;
    }

    &__form {
      width: 500px;
      min-height: 320px;
      padding: 70px 90px;

      &--with-sso {
        padding: 30px 90px;
      }
    }

    &__form-header {
      font-size: 40px;
    }

    &__form-submit--sso {
      font-size: 16px;
      text-align: center;
    }
  }
}
