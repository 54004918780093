.details-container {
  display: flex;
  width: 100%;
  flex-direction: column;

  .ui-tabs {
    margin: 0 20px 10px;
  }

  .list-wrapper {
    padding: 0 20px 20px;
  }

  &__header {
    display: flex;
    min-height: 38px;
    flex: 0 0 auto;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid $color-lines-primary;
    margin: 10px 20px;
    box-shadow: 4px 19px 20px rgba($color-light-silver, 0.1);
    color: $color-font-primary;
    font-size: 24px;

    a {
      color: $color-font-primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__header-preview-link {
    text-decoration: none !important;
  }

  &__header-text {
    @include line-clamp(4);

    margin-right: auto;

    &--inactive {
      opacity: 0.6;
    }
  }

  &__header-element {
    margin: 0 5px;
    cursor: pointer;
  }

  &__header-icons {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
  }

  &__header-elements {
    display: flex;
    align-items: center;

    &--left {
      margin-right: 2rem;
    }

    &--inactive {
      opacity: 0.6;
      pointer-events: none;
    }
  }
}

.details-form {
  overflow: auto;
  height: 100%;
  padding: 0 20px 20px;
}

.header-container {
  margin-top: 20px;
}
