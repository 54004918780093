.ui-messages {
  &__header {
    display: flex;
    align-items: center;
    padding-bottom: 6px;
    border-bottom: 1px solid $color-gallery;
    margin: 10px 0 16px;
  }

  &__header-text {
    margin-right: auto;
    color: $color-brand-primary;
    font-size: 21px;
  }

  &__toggle {
    margin-right: 20px;
  }

  &__templates {
    width: 264px;
    margin-right: 20px;
  }

  &__button-reply {
    border: 1px solid $color-boulder;
    background-color: $color-boulder;
    color: $color-white;
  }

  .button:not(:last-of-type) {
    margin-right: 6px;
  }
}

#reply-form {
  margin: 10px 0 20px;

  textarea {
    height: 300px;
  }
}
