[class^='icon-'],
[class*=' icon-'] {
  display: inline-block;
  border: 0;
  margin: 0;
  background: none;
  box-shadow: none;
  text-indent: 0;
}

.button [class^='icon-'],
.button [class*=' icon-'] {
  display: inline-block;
  margin-top: 1px;
  font-size: 18px;
}

.icon-table-platform {
  margin-right: 3px;
  font-size: 14px;
}

.icon-play--source {
  width: 25px;
  height: 25px;
  margin: 5px 10px 5px 0;
  cursor: pointer;
  font-size: 26px;
}

.no-active-icon {
  margin: 5px;
  color: $color-brand-primary;
  font-size: 20px;
  font-weight: bold;
}

.no-title-icon {
  width: 16px;
  margin-right: 4px;
  color: $color-brand-primary;
  font-weight: bold;
}
