.ui-player {
  top: 10%;
  right: 10%;
  bottom: 10%;
  left: 10%;
  width: initial;
  border: none;
  margin-left: 0;
  background-color: $color-black;
  box-shadow: 0 0 18px rgba($color-cod-gray, 0.8);
  text-align: center;

  &__holder {
    width: 100%;
    height: 100%;
    background-color: $color-black;
  }

  .ui-modal {
    &__wrapper {
      height: 100%;
    }

    &__content-wrapper {
      height: 100%;
      padding: 0;
    }

    &__inner,
    &__content {
      height: 100%;
      border: none;
    }

    &__content {
      overflow: visible;
    }
  }
}

.ui-player-error {
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  left: 0;
  min-height: 110px;
  padding: 20px 30px;
  background: rgba($color-error, 0.7);
  color: $color-white;
  text-align: left;

  &__title {
    padding-bottom: 5px;
    border-bottom: 1px solid $color-well-read;
    margin-bottom: 14px;
    color: $color-white;
    font-size: 22px;
  }

  p {
    display: flex;
    margin: 4px 0 0;
    color: $color-gallery;
  }

  span {
    min-width: 80px;
    align-self: flex-end;
    margin-right: 4px;
    color: $color-cosmos;
    font-size: 12px;
  }
}
