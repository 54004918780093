.section-container {
  padding: 20px;

  &__sections {
    flex-grow: 1;
  }

  &__sections-wrapper {
    overflow-y: auto;
  }

  .list-wrapper {
    padding: 0;
    margin-right: 20px;
    overflow-y: hidden;
  }

  .ui-list-nav {
    min-height: auto;
    padding: 14px 16px;
    margin-bottom: 10px;
  }

  .ui-search {
    width: 156px;
    height: auto;
    flex-shrink: 0;
    margin-right: 6px;

    .ui-search__input {
      height: 32px;
    }
  }

  .ui-loader {
    display: none;
  }
}

.loading .ui-loader {
  display: block;
}

.section {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 8px 16px;
  border: 1px solid $color-alto;
  background-color: $color-white;
  box-shadow: 0 0 20px rgba($color-light-silver-chalice, 0.1);

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &__list {
    overflow: hidden;
    width: 100%;
    padding-top: 8px;
    border-top: 1px solid $color-gallery;
    margin-top: 8px;
  }

  .section-item-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  .section-item-list-wrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }
}

.section-ghost {
  border: 1px solid $color-light-silver;
}

.section-labels {
  display: flex;
  margin-bottom: 0;

  label {
    flex-basis: 0 !important;
  }

  .section-all {
    flex: 0 0 28px;
    margin-left: 20px;

    label {
      order: 1;
      margin-left: 8px;
    }
  }
}
