.fixed-textarea-wrapper .ui-form-input__input {
  min-height: 400px;
  resize: vertical;
}

.hidden-file-input {
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
}

.input-modified-color {
  background-color: $color-modified;
}

.input-invalid-color {
  background-color: $color-error;
}

.error-input-wrapper .ui-form-input__input {
  background-color: $color-error;
}

.success-input-wrapper .ui-form-input__input {
  background-color: $color-shamrock;
}

.wallet-quantity {
  margin-top: 4px;

  &__label {
    margin-right: 15px;
    color: $color-silver-chalice;
    font-size: 12px;
    text-transform: uppercase;
  }

  &__amount {
    color: $color-dove-gray;
    font-size: 14px;
  }
}

.ui-checkbox {
  align-items: baseline;

  &__checkmark {
    line-height: 16px;
  }
}

.ui-date-picker__icon {
  cursor: pointer;
}

.ui-text-editor .tox-tinymce,
.ui-plain-editor {
  height: 430px !important;
}

.ui-form-item {
  .ui-autocomplete-inline__inner,
  .ui-dictionary-select__select,
  .ui-time-input,
  .ui-input,
  .ui-textarea,
  .multiselect {
    margin-bottom: 5px;
  }

  label {
    display: inline-block;
    flex: 0 0 140px;
    padding-right: 22px;
    margin-top: 6px;
    color: $color-silver-chalice;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    text-transform: uppercase;
  }

  &--has-error {
    .ui-input,
    .ui-textarea,
    .multiselect__tags {
      border-color: $color-error;
    }
  }

  &--is-mandatory {
    label:not(.ui-multiple-choice__item-label) {
      position: relative;

      &:after {
        position: absolute;
        top: 2px;
        right: 5px;
        color: $color-error;
        content: '*';
        font-size: 14px;
      }
    }
  }

  &--is-mandatory#{&}--has-tooltip {
    label {
      padding-right: 32px;
    }
  }
}

.ui-tooltip__question {
  display: inline-block;
}
