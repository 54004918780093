.sections-sidebar {
  display: flex;
  min-width: 494px;
  max-width: 494px;
  height: 100%;
  flex-direction: column;
  padding: 20px;
  border: 1px solid $color-lines-primary;
  margin-bottom: 0;
  background-color: $color-white;
  box-shadow: 0 0 20px rgba($color-light-silver-chalice, 0.1);

  &__tabs {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    padding-bottom: 8px;
    border-bottom: 1px solid $color-gallery;
  }

  &__tab-button {
    padding: 6px 12px;
    margin-right: 3px;
    background-color: $color-wild-sand;
    color: $color-dove-gray;
    cursor: pointer;

    &--active {
      background-color: $color-brand-primary;
      color: $color-white;
    }
  }
}

.sidebar-content {
  display: flex;
  overflow: hidden;
  flex: 1;
  flex-direction: column;

  &__filters {
    display: flex;
    flex-shrink: 0;
    padding-left: 5px;
    margin: 10px 0;

    .ui-search__icon {
      display: none;
    }

    .ui-checkbox {
      margin: 6px 4px 0 8px;
    }

    .ui-date-picker__modal-wrapper {
      right: 0;
      left: unset;
    }
  }

  &__search {
    flex: 1;
    margin-right: 5px;

    input {
      width: 100%;
    }
  }

  &__select {
    flex: 0 0 134px !important;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  &__list {
    overflow-y: auto;
  }

  &__list-item {
    display: flex;
    padding: 4px 10px;
    cursor: default;

    &:hover {
      background-color: $color-wild-sand;
    }
  }

  &__list-item-image {
    width: 72px;
    height: 42px;
    border: 1px solid $color-lines-primary;
    background-color: $color-bg-primary;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: move;
    vertical-align: middle;
  }

  &__list-item-description {
    display: flex;
    overflow: hidden;
    flex: 1;
    flex-direction: column;
    padding-left: 14px;
  }

  &__list-item-name {
    @include truncate;

    color: $color-dove-gray;
    font-size: 15px;
  }

  &__list-item-categories {
    display: flex;
    flex-wrap: wrap;
  }

  &__list-item-category {
    padding: 1px 5px;
    margin: 3px 3px 0 0;
    background-color: $color-silver-chalice;
    color: $color-white;
    font-size: 12px;

    &--main {
      background-color: $main-category-pill-color;
    }

    &--category {
      background-color: $category-pill-color;
    }
  }

  &__list-item-datetime {
    width: max-content;
    padding: 1px 5px;
    margin-bottom: 2px;
    background-color: $color-silver-chalice;
    color: $color-white;
    font-size: 12px;
  }

  &__no-data-info {
    margin: 20px;
    color: $color-gray;
    text-align: center;
  }
}
