.ui-list-nav {
  display: flex;
  min-height: 76px;
  flex-shrink: 0;
  padding: 20px 26px 8px;
  border: 1px solid $color-alto;
  margin-bottom: 16px;
  background-color: $color-white;
  box-shadow: 0 0 20px rgba($color-light-silver-chalice, 0.1);

  &__buttons-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    margin-left: 12px;

    &--modified {
      background-color: $color-modified;
    }
  }

  &__wrapper-right {
    display: flex;
    margin-left: auto;
  }

  &__active-filters {
    height: 100%;
    flex: 1;
    margin-top: -7px;
    margin-left: 14px;
    transform: translateY(-8px);
  }

  &__available-filters {
    display: inline-block;
    width: 186px;
    margin-right: -8px;
    margin-left: 14px;
  }
}
