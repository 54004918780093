@keyframes fade {
  0% {
    border: 1px solid $color-white;
    background-color: $color-white;
  }

  50% {
    border: 1px solid $color-light-silver;
    background-color: $color-wild-sand;
  }

  100% {
    border: 1px solid $color-white;
    background-color: $color-white;
  }
}

.responsive-item {
  position: relative;
  width: 100%;
  float: left;

  &:before {
    display: block;
    padding-top: 66%;
    content: '';
  }

  .image-wrapper {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    border: 1px solid $color-alto;
  }

  .image {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.responsive-item-wrapper {
  width: 14.2857%;
  flex: 0 0 14.2857%;
}

@media only screen and (max-width: 1800px) {
  .responsive-item-wrapper {
    width: 20%;
    flex: 0 0 20%;
  }
}

@media only screen and (max-width: 1600px) {
  .responsive-item-wrapper {
    width: 20%;
    flex: 0 0 20%;
  }
}

@media only screen and (max-width: 1400px) {
  .responsive-item-wrapper {
    width: 25%;
    flex: 0 0 25%;
  }
}

@media only screen and (max-width: 1250px) {
  .responsive-item-wrapper {
    width: 25%;
    flex: 0 0 25%;
  }
}

@media only screen and (max-width: 1000px) {
  .responsive-item-wrapper {
    width: 33.3333%;
    flex: 0 0 33.3333%;
  }
}

@media only screen and (max-width: 800px) {
  .responsive-item-wrapper {
    width: 50%;
    flex: 0 0 50%;
  }
}

@media only screen and (max-width: 700px) {
  .responsive-item-wrapper {
    width: 100%;
    flex: 0 0 100%;
  }
}

.sortable-item {
  cursor: move;
}

.section-item {
  position: relative;
  display: flex;
  overflow: hidden;
  width: 100%;
  flex: 0 0 100%;
  padding: 2px;
  margin: 0 0 1px -4px;
  cursor: default;

  &--is-sorted {
    opacity: 0.4;

    .title a {
      color: $color-black !important;
    }
  }

  &--is-inactive {
    opacity: 0.4;
  }

  &:hover {
    background: $color-darker-alabaster;
  }

  .section-item-drag {
    position: static;
    z-index: 10;
    display: block;
    width: auto;
    height: auto;
    margin-right: 8px;
    cursor: move;
    font-size: 20px;

    &:before {
      opacity: 1;
    }
  }

  .image-wrapper {
    display: none;
  }

  .responsive-item {
    display: none;
  }

  a {
    padding-left: 6px;
    color: $color-dodger-blue;
  }

  .title {
    position: static;
    z-index: 1;
    right: 11px;
    bottom: 11px;
    left: 11px;
    overflow: hidden;
    min-width: 36%;
    max-width: 36%;
    padding: 0 14px 0 0;
    color: $color-gray;
    font-size: 14px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__content {
    display: flex;
    flex-wrap: wrap;
    margin: 0 15px;
    color: $color-gray;
    font-size: 12px;
  }

  &__content-element:not(:last-of-type) {
    margin-right: 8px;
  }

  &__content-element-label {
    color: $color-black;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__actions {
    display: flex;
    min-width: 60px;
    align-items: flex-start;
    justify-content: flex-end;
    margin-left: auto;
  }

  &__actions-warning {
    margin-top: 1px;
    color: $color-error;
  }

  &__actions-button {
    margin-left: 6px;
    color: $color-mine-shaft;
    cursor: pointer;
    font-size: 15px;
  }
}

.ghost {
  padding-left: 30px !important;

  .title a {
    color: $color-black !important;
  }
}

.section-item-ghost.list {
  display: none;
}

.product-draggable {
  position: absolute !important;
  z-index: 100;
  display: flex;
  width: 132px;
  height: 90px;
  padding: 4px;
  border: 1px solid $color-silver-chalice;
  background: $color-white;
  pointer-events: none;

  .image {
    width: 100%;
    border: 1px solid $color-light-silver;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.product-ghost .title {
  margin-left: 36px;
}

.drag-invitation {
  .image-wrapper {
    border: none;
  }

  .image {
    bottom: 40px;
    background-size: contain;
  }

  .title {
    position: absolute;
    bottom: 10px;
    padding: 0 10%;
    color: $color-boulder;
    font-size: 12px;
    text-align: center;
  }
}
