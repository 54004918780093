.ui-detail {
  position: relative;
  overflow: auto;
  height: 100%;
  padding: 0 20px 20px;

  &--sticky-save {
    overflow: hidden;

    .ui-detail__form {
      height: 100%;
      padding: 0;
    }

    .ui-form__columns {
      overflow: auto;
      padding: 16px 26px 8px;
    }

    .ui-form__buttons {
      margin: 16px 26px;
    }
  }

  &--readonly-detail {
    .ui-detail__form .ui-form-item label {
      flex: 0 0 210px;
    }
  }

  &__form {
    padding: 16px 26px;
    border: 1px solid $color-lines-primary;
    margin-bottom: 16px;
    background-color: $color-white;

    .ui-form-item label {
      flex: 0 0 170px;
    }

    .ui-form-item__error {
      margin-left: 170px;
    }
  }

  .ui-form__column {
    &--0 {
      max-width: 38%;
      flex: 0 0 38%;
    }

    &--1 {
      flex: 1 1 auto;
      padding-left: 20px;
      margin-left: 18px;
    }

    &:only-child {
      max-width: unset;
      flex: auto;
    }
  }
}
