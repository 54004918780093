/* stylelint-disable  scss/dollar-variable-empty-line-before */
$icomoon-font-path: '../fonts/icons/fonts';
$loader-image-url: '/static/img/loader.svg';

$color-lines-primary: $color-alto;
$color-brand-primary: $color-tamarillo;
$color-brand-secondary: $color-tundora;
$color-bg-primary: $color-alabaster;
$color-font-primary: $color-tundora;
$color-error: $color-light-red;

$header-height: 70px;

$main-category-pill-color: $color-brand-secondary;
$category-pill-color: $color-orange;

$filter-container-width: 364px;
$color-modified: $color-goldenrod;

$ui-multiple-choice-loader-image-url: $loader-image-url;
$ui-table-loader-image-url: $loader-image-url;
