.subscriber-detail {
  overflow: auto;

  .ui-detail {
    overflow: unset;
    height: unset;
    margin-bottom: 0;
  }

  .ui-panel {
    margin: 0 20px 20px;
  }
}
