html {
  direction: ltr;
}

html,
body {
  overflow: hidden;
  height: 100%;
}

#main-container {
  display: flex;
  height: 100%;
}

.main-container--with-header {
  padding-top: $header-height;
}

.app {
  &__info {
    max-width: 600px;
    margin: 16rem auto auto;
    font-size: 1.25rem;
    text-align: center;
  }
}

.content {
  display: flex;
  overflow: hidden;
  flex: 1 1 auto;
  background: $color-bg-primary;

  .ui-tabs {
    margin: 20px 20px -8px;
  }

  .list-wrapper,
  &.list-wrapper {
    padding: 20px;
  }
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.ml-1 {
  margin-left: 0.5rem;
}

.unselectable {
  user-select: none !important;
}

@import 'common/details';
@import 'common/packet-details';
@import 'common/section';
@import 'common/list';
@import 'common/dashboard';
