.dashboard {
  display: grid;
  overflow: auto;
  padding: 20px;
  grid: repeat(auto-fill, 260px) / repeat(auto-fill, minmax(550px, 1fr));
  grid-gap: 15px 18px;
}

.dashboard-chart {
  padding-top: 8px;
  margin: 0;

  &__menu {
    position: relative;
    display: flex;
  }

  &__header {
    padding: 8px 0;
    color: $color-boulder;
    font-size: 18px;
  }

  &__options {
    position: absolute;
    right: 0;
  }

  &__select {
    width: auto;

    &:last-of-type {
      margin-left: 4px;
    }
  }

  canvas {
    width: 100% !important;
    height: 195px !important;
  }
}
