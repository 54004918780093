.ui-product-file-image {
  position: relative;
  border: 1px solid $color-light-silver;
  margin: 0 10px 25px 0;

  &--with-error {
    border: 2px solid $color-error;
  }

  &--transparency-grid {
    background-image: conic-gradient($color-gallery 0 25%, transparent 0 50%, $color-gallery 0 75%, transparent 0);
    background-size: 10px 10px;
  }

  &__actions {
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 5px;
    left: 5px;
    display: flex;
    height: 24px;
    align-items: center;
    padding: 0 5px;
    background-color: rgba($color-mine-shaft, 0.5);
    color: $color-white;
  }

  &__actions-text {
    margin-right: auto;
  }

  &__actions-button {
    margin-left: 4px;
    cursor: pointer;
  }

  &__preview {
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &__error-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
  }

  &__error {
    color: $color-error;
    font-size: 36px;
  }

  &__add {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &__add-icon {
    display: block;
    margin-top: 12px;
    color: $color-boulder;
    font-size: 54px;
  }
}
