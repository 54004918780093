.ui-filters {
  position: fixed;
  z-index: 10;
  top: $header-height;
  right: -($filter-container-width);
  bottom: 0;
  display: flex;
  width: $filter-container-width;
  flex-direction: column;
  padding: 16px 12px;
  border-right: 1px solid $color-gallery;
  background-color: $color-white;
  box-shadow: 0 0 24px rgba($color-dove-gray, 0.1);
  transition: right 0.2s ease-in-out;

  &--is-visible {
    right: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 7px 10px;
    background-color: $color-brand-primary;
    color: $color-white;
  }

  &__header-clear {
    margin-right: auto;
    margin-left: 8px;
    color: $color-white;
    cursor: pointer;
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__header-close {
    cursor: pointer;
    font-size: 12px;
  }

  &__list {
    overflow: auto;
    height: 100%;
  }
}
