.ui-loader {
  height: min-content;
  padding: 30px 40px;
  border: 1px solid $color-lines-primary;
  margin: 35% auto 0;
  background-color: $color-white;

  &--overlay {
    display: flex;
    justify-content: center;

    &:before {
      position: fixed;
      z-index: 100;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($color-white, 0.6);
      content: '';
    }
  }

  &__text {
    padding: 6px 0 6px 42px;
    background: url('#{$loader-image-url}') no-repeat 0 3px / 32px 32px;
    color: $color-dove-gray;
    font-size: 17px;

    .ui-loader--overlay & {
      position: absolute;
      z-index: 110;
      top: 50%;
      padding: 30px 40px 30px 80px;
      border: 1px solid $color-lines-primary;
      background-color: $color-white;
      background-position: 30px 50%;
      transform: translateY(-50%);
    }
  }
}
